.login-page {
  width: 100%;
  height: 100vh;

  display: flex;
  justify-content: center;
  align-items: center;

  background: url('./images/login_bg.png') no-repeat center center fixed;
  -webkit-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}

.login-page .login-logo {
  position: absolute;
  top: 3%;
  left: 3%;
  width: 180px;
}

.login-page .login-container {
  width: 100%;

  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;

  @media (min-width: 900px) {
    max-width: 1200px;
    padding: 0 80px;

    flex-direction: row;
  }

  @media (min-width: 1200px) {
    padding: 0 160px;
  }
}

.login-page .login-container h1 {
  max-width: 445px;

  /* font-family: 'Futura Md BT'; */
  font-weight: 400;
  font-size: 48px;
  line-height: 56px;

  color: #FFFFFF;
}

.login-page .login-form {
  width: 400px;

  background: #F9FAFB;
  box-shadow: 0px 12px 24px rgba(51, 36, 58, 0.16);
  color: #fff;

  padding: 80px 36px;
}

.login-page .login-form h2 {
  /* font-family: 'ITC Lubalin Graph'; */
  font-style: normal;
  font-weight: 500;
  font-size: 32px;
  line-height: 38px;
  text-align: center;
  
  color: #140137;

  margin-bottom: 65px;
}

.login-page .login-form button,
.login-page .login-form button:hover {
  background: #140137;

  font-weight: 600;

  margin-top: 50px;
}

.login-page .login-form .ant-form-item + .ant-form-item {
  margin-top: 24px;
}

.ant-layout-header.logo {
  height: 64px;

  display: flex;
  justify-content: center;
  align-items: center;

  background: #081426;

  padding: 0;
}

.ant-layout-header.logo img {
  height: 45px;
}

.page-content-layout {
  min-height: 100vh;
  padding: 32px;
}

.ant-layout-header {
  display: flex;
  justify-content: space-between;
}