.claim-card,
.ant-form:not(#login) {
  display: grid;
  grid-template-columns: repeat(6, minmax(0, 1fr));
  grid-auto-flow: column;
  grid-gap: 16px;
}

.ant-form-item {
  margin-bottom: 0;
}

.ant-form-item .ant-row {
  display: flex;
  flex-direction: column;
}

.ant-form-item .ant-col {
  min-height: unset;
  text-align: left;
}

.ant-form-item .ant-picker,
.ant-form-item .ant-input-number {
  width: 100%;
}

.ant-card + .ant-card {
  margin-top: 16px;
}

.float-btn {
  float: right;
}